/* global config, Promise, angular */

'use strict';

angular.module('managerApp').factory('clients', function (http, toast) {
    const url = config.apiURL + 'clients/';

    let errors = [401, 403, 409, 422, 500];
    function errorHandler(err) {
        if (err) {
            if (err.message) {
                if (err.type !== 'HTTPService') {
                    return Promise.reject(err.message);
                } else {
                    toast.show(err.message, 'error', true);
                }
            } else {
                if (err.data && err.data.message) {
                    return Promise.reject(err.data.message);
                }
            }
            return Promise.reject(err);
        }
        return Promise.reject();
    }
    function prepareFilters(filters) {
        let f = '';
        for (let i in filters) {
            f += (f === '' ? '?' : '&') + 'filter[' + i + ']=' + filters[i];
        }
        return f;
    }
    return {
        get: (by, page = 1, pageSize) => {
            let _url = url, params = [];
            return new Promise((resolve, reject) => {
                if (by) {
                    if (by.type) {
                        params.push('filter[type]=' + by.type);
                    }
                    if (by.id) {
                        _url += by.id;
                    } else { // options only for many results

                        for (let i in by) {
                            if (['id', 'type'].indexOf(i) < 0) {
                                params.push('filter[' + i + ']=' + by[i]);
                            }
                        }
                        if (page > 1) {
                            params.push('page=' + parseInt(page));
                        }
                        if (pageSize) {
                            params.push('pageSize=' + parseInt(pageSize));
                        }
                    }
                }
                if (params.length > 0) {
                    _url += '?' + params.join('&');
                }
                http.get(_url).then((res) => {
                    if (typeof res.data.count !== 'undefined' &&
                            typeof res.data.rows !== 'undefined') {
                        resolve({
                            count: res.data.count,
                            items: res.data.rows
                        });
                    } else {
                        resolve(res.data);
                    }
                }, (err) => {
                    if (err) {
                        if (err.message) {
                            if (err.type !== 'HTTPService') {
                                return reject(err.message);
                            } else {
                                toast.show(err.message, 'error', true);
                            }
                        }
                        reject(err);
                    }
                    reject();
                });
            });
        },
        _get: (by) => {
            let _url = url, params = [];
            return new Promise((resolve, reject) => {
                if (by) {
                    if (by.id) {
                        _url += by.id;
                    } else { // options only for many results
                        if (by.page && by.page > 1) {
                            params.push('page=' + parseInt(by.page));
                        }
                        if (by.pageSize) {
                            params.push('pageSize=' + parseInt(by.pageSize));
                        }
                    }
                }
                if (params.length > 0) {
                    _url += '?' + params.join('&');
                }
                http.get(_url).then((res) => {
                    if (res.data.count && res.data.rows) {
                        resolve({
                            count: res.data.count,
                            items: res.data.rows
                        });
                    } else {
                        resolve(res.data);
                    }
                }, (err) => {
                    if (err.data && err.data.message) {
                        return reject(err.data.message);
                    }
                    reject(err.data);
                });
            });
        },
        me: () => {
            return new Promise((resolve, reject) => {
                let _url = url + 'me';
                http.get(_url).then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        activateAccount: (client) => {
            if (!client || !client._id) {
                return Promise.reject();
            }
            let _url = url + client._id + '/activate-from-bo';
            return new Promise((resolve, reject) => {
                http.put(_url).then((res) => {
                    resolve(res.status === 204);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        addAddress: (client, address) => {
            if (!client || !address) {
                return Promise.reject();
            }
            let _url = url + client._id + '/addresses';
            return new Promise((resolve, reject) => {
                http.post(_url, address).then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        updateAddress: (client, address) => {
            if (!client || !client._id || !address || !address._id) {
                return Promise.reject();
            }
            let _url = url + client._id + '/addresses/' + address._id;
            return new Promise((resolve, reject) => {
                http.put(_url, address).then((res) => {
                    resolve(res.status === 204);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        deleteAddress: (client, address) => {
            if (!client || !client._id || !address || !address._id) {
                return Promise.reject();
            }
            let _url = url + client._id + '/addresses/' + address._id;
            return new Promise((resolve, reject) => {
                http.delete(_url).then(() => {
                    resolve();
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        getProcedures: (id, by, page = 1, pageSize) => {
            let _url = url + id + '/procedures/', params = [];
            return new Promise((resolve, reject) => {
                if (by) {
                    if (by.type) {
                        params.push('filter[type]=' + by.type);
                    }
                    if (by.id) {
                        _url += by.id;
                    } else { // options only for many results

                        for (let i in by) {
                            if (['id', 'type'].indexOf(i) < 0) {
                                params.push('filter[' + i + ']=' + by[i]);
                            }
                        }
                        if (page > 1) {
                            params.push('page=' + parseInt(page));
                        }
                        if (pageSize) {
                            params.push('pageSize=' + parseInt(pageSize));
                        }
                    }
                }
                if (params.length > 0) {
                    _url += '?' + params.join('&');
                }
                http.get(_url).then((res) => {
                    if (typeof res.data.count !== 'undefined' &&
                            typeof res.data.rows !== 'undefined') {
                        resolve({
                            count: res.data.count,
                            items: res.data.rows
                        });
                    } else {
                        resolve(res.data);
                    }
                }, (err) => {
                    if (err) {
                        if (err.message) {
                            if (err.type !== 'HTTPService') {
                                return reject(err.message);
                            } else {
                                toast.show(err.message, 'error', true);
                            }
                        }
                        reject(err);
                    }
                    reject();
                });
            });
        },
        add: (client) => {
            return http.post(url, client).then((res) => {
                return Promise.resolve(res);
            }).catch(errorHandler);
        },
        update: (data) => {
            return new Promise((resolve, reject) => {
                http.put(url + data._id, data).then((resp) => {
                    resolve(resp);
                }, (resp) => {
                    if (errors.indexOf(resp.status) > -1) {
                        reject(resp.message);
                    }
                });
            });
        },
        delete: (client) => {
            return new Promise((resolve, reject) => {
                http.delete(url + (client._id ? client._id : client)).then((resp) => {
                    resolve(resp);
                }, (err) => {
                    if (err.message) {
                        reject(err);
                    }
                    if (errors.indexOf(err.status) > -1) {
                        reject(err.data.message);
                    }
                });
            });
        },
        createPrepaidAccount: (clientId, data) => {
            let _url = url + clientId + '/prepaid-account';
            return new Promise((resolve, reject) => {
                http.put(_url, data).then((res) => {
                    resolve(res);
                }, (err) => {
                    reject(err);
                });
            });
        },
        getPrepaidAccount: (clientId = null) => {
            if (clientId === null) {
                return Promise.reject('NoData');
            }
            let _url = url + clientId + '/prepaid-account';
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        addDocument: (clientId = null, document = null) => {
            if (clientId === null || document === null) {
                return Promise.reject('NoData');
            }
            let _url = url + clientId + '/documents/add';
            return new Promise((resolve, reject) => {
                http.put(_url, document).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        uploadDocument: (clientId = null, documentId = null, document = null, documentType = null) => {
            if (clientId === null || documentId === null || document === null || documentType === null) {
                return Promise.reject('NoData');
            }
            let _url = url + clientId + '/documents/' + documentId + '?type=' + documentType;
            return new Promise((resolve, reject) => {
                http.put(_url, document, {
                    headers: {'Content-Type': undefined}
                }).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        acceptDocument: (clientId = null, documentId = null) => {
            if (clientId === null || documentId === null) {
                return Promise.reject('NoData');
            }
            let _url = url + clientId + '/documents/' + documentId + '/accept';
            return new Promise((resolve, reject) => {
                http.put(_url).then((res) => {
                    if (res) {
                        resolve(res);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },        
        getDocuments: (clientId = null) => {
            if (clientId === null) {
                return Promise.reject('NoData');
            }
            let _url = url + clientId + '/documents';
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        getMyTerminals: () => {
            let _url = url + 'me/terminals';
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        getMyTablets: () => {
            let _url = url + 'me/tablets';
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        getMyWebApps: () => {
            let _url = url + 'me/webapps';
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        getPartners: (filters) => {
            let f = prepareFilters(filters);
            let _url = url + 'partners' + f;
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        getMyDevicesManagers: () => {
            let _url = url + 'me/devices-managers';
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        addDevicesManager: (data) => {
            let _url = url + 'me/devices-managers';
            return new Promise((resolve, reject) => {
                http.put(_url, data).then((res) => {
                    resolve(res);
                }, (err) => {
                    reject(err);
                });
            });
        },
        updatePassword: (clientId, data) => {
            let _url = url + clientId + '/password';
            return new Promise((resolve, reject) => {
                http.put(_url, data).then((res) => {
                    resolve(res);
                }, (err) => {
                    reject(err);
                });
            });
        },
        getMyCurrentChats: () => {
            let _url = url + 'me/chats';
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },        
//------------------------------------------------------------- dashboard ------
        dashboard_getEvolutionStats: (filters) => {
            let f = prepareFilters(filters);
            let _url = url + 'dashboard/procedures/evolutions' + f;
            return http.get(_url).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        },
        dashboard_getPaidProceduresCounts: (filters) => {
            let f = prepareFilters(filters);
            let _url = url + 'dashboard/procedures/paid' + f;
            return http.get(_url).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        },
        dashboard_getTurnover: (filters) => { /* nb demarches + chiffre d'affaires */
            let f = prepareFilters(filters);
            let _url = url + 'dashboard/procedures/turnover' + f;
            return http.get(_url).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        },
        dashboard_getTurnoverPerPeriod: (filters) => { /* chiffre d'affaires */
            let f = prepareFilters(filters);
            let _url = url + 'dashboard/procedures/turnover/period' + f;
            return http.get(_url).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        },
        dashboard_getTurnoverExport: (filters) => { /* chiffre d'affaires */
            let f = prepareFilters(filters);
            let _url = url + 'dashboard/procedures/turnover/export' + f;
            return http.get(_url).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        },
        dashboard_getCashPaymentsExport: (filters) => { /* chiffre d'affaires */
            let f = prepareFilters(filters);
            let _url = url + 'dashboard/cash-payments/export' + f;
            return http.get(_url).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        }
    };
});
